.distribution-detail-root{
        width: 90%;
    margin: auto;
    margin-top: 1rem;

}
.top-card{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    border-radius: 10px;
    width: 90%;
}
.distribution-info-container{
    display: flex;
    justify-content: space-between;
        width:50%;
        border-radius: 10px;
}
.received-money-container{
    display:flex;
    color: grey;
    margin-bottom: 10px;
    margin-top: 15px;
}
.received-money-container h2{
    font-size: 3rem;
    color: var(--app-money-green);
}
.received-money-container p {
    font-size: 1.5rem;
    margin-top: 10px;

}

.total-received-title{
    font-size: 1.2rem;
    color: grey;
    font-weight: bold;
}

.pending{
    color: #5DADE2 !important;
}

.distribution-note-container{
    margin-top: 2rem;
    width: 95%;
}
.distribution-note-container p{
    text-align: justify;
}

#distribution-company-det h2{
font-size: 1.8rem;
color: var(--app-Theme);
}

.payment-distribution-container{
    margin-top: 2rem;
}

#comment-file-container{
    margin-top: 10px;
}
#comment-file-container img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
      border:  1px solid whitesmoke;
}
#comment-file-container video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:45vh;
    border-radius:10px;
    border:  1px solid whitesmoke;
}
#comment-body{
    margin-left:60px;
    width: 90%;
}

.comment-file-container{
    display: flex;
    justify-content: center;
align-items: center;
flex-direction: column;
}
.comment-file-container p{
color: grey;
margin-top: 10px;
}

.file-wrapper{
    padding: 30px 40px;
}
.file-wrapper svg{
   font-size: 10rem;
}

.distribution-receipt-container{
    margin-top: 2rem;
    margin-bottom: 4rem;
}