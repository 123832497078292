
#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 20px;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}

#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
}
#info-container P {

  margin-top: 8px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}

#biography-container {
 width: 90%;

}
#biography-container p {
  text-align: justify;
  color: dimgray;
  font-size: 1.1rem;
  line-height: 1.7;
}
#biography-container h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}


.verified-svg{
  font-size: 1.3rem;
}

.partnerships-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#social-media-container{
  display: flex;
  margin-top: 15px;
}
#social-media-container svg{
  font-size: 1.2rem;
  color: var(--app-theme);
    cursor: pointer;
  margin-right: 17px;
}

.enterprise-location-icon-container{
  display: flex;
}
.enterprise-location-icon-container svg{
  margin-right: 10px;
  font-size: 1.5rem;
}
.enterprise-location-icon-container p{
  font-size: 1.2rem;
}
.enterprise-location-text{
  color: grey;
  font-size: 1.1rem;
  margin-left: 15px;
}