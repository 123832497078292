.search-bar-root-container {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex: 12;
  flex-direction: row;
  box-sizing: border-box;
  background: white;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.search-area {
  flex: 10;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}


.search-input {
  border: 0px !important;
  width: 50vw !important;
  padding: 10px;
  margin: 0px !important;
 width: 58%;
 box-sizing:border-box;
}
.filter-btn {
  display: flex;
  border: 1px solid whitesmoke;
  padding: 8px 10px;
  border-radius: 20px;
  background-color: #fef8ff;
  cursor: pointer;
}
.filter-btn small {
  color: var(--app-accent-pink);
  margin-left: 5px;
  font-weight: bold;
}

.filter-btn svg {
  color: var(--app-accent-pink);
  cursor: pointer;
  font-size: 0.8rem;
}



@keyframes show-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}


.filter-field-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
 margin-top: 15px;
 animation: show-down;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.search-filter-checkboxes .checkbox-empty-box {
  height: 15px;
  width: 15px;
  border: solid 4px var(--app-theme-lighter);
  margin: 4px;
  border-radius: 2px;
}

@media (max-width: 480px) {
.search-input {
  width: 40vw !important;
  font-size: 0.8rem;
  padding: 0px;
}
.filter-btn{
  padding: 5px 7px;
}
.filter-field-container {
    grid-template-columns: repeat(3, 1fr);
}
.search-filter-checkboxes .checkbox-default-container {
    padding: 1px;
}

.search-filter-checkboxes .checkbox-empty-box {
    height: 8px !important;
    width: 8px !important;
}


.search-filter-checkboxes .checkbox-default-container p {
    font-size: 0.7rem;
}

.search-filter-checkboxes .hovering-checkmark {
  font-size: 0.7rem;
  font-weight: bold;
}
}