.response-wrapper{
    padding: 20px 40px;
   border-top-right-radius: 0px;
   border-top-left-radius: 60px;
   border-bottom-right-radius: 60px;
   border-bottom-left-radius: 0px;
}


#response-btns-container{
    display: flex;
    justify-content: space-evenly;
}

#response-intro-container{
    margin-top: 1.6rem;
    margin-bottom: 2rem;
}

#res-about-container{
      margin-bottom: 3rem;
}
#res-about-container p{
     text-align: justify;
}
#res-name-section{
    margin-top: 2rem;
}

.opp-response-main-container{
    width: 50vw;
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.invitation-not-found-message-container{
    padding: 10px 20px;
    border-radius: 10px;
    width: 60%;
}

.message-body-container{
    margin-top: 15px;
    text-align: justify;
}
.message-body-container span{
    color: rgb(61, 60, 60);
    /* font-size: 1rem; */
}
.message-body-container p{
     margin-top: 15px;
    font-size: 1.1rem;
    text-align: right;
    font-weight: bold;
   color: rgb(61, 60, 60);
}
.response-wrapper {
    max-height: 80vh;
    overflow-y: scroll;
}


@media (max-width: 480px) {
.opp-response-main-container {
    width: 95vw;
}
.response-wrapper {
    padding: 20px 15px;
}
.invitation-not-found-message-container{
    width: 80%;
}
}