.root-wrapper {
  display: grid;
  grid-template-columns: var(--side-ratio) auto;
  width: 100%;
  height: 100vh;
}

.children-container {
  position: relative;
  margin-top: 2rem;
}

.side-pane {
  position: absolute;
  top: 61px;
  right: 0px;
  width: 300px;
  height: 100vh;
  background: white;
}

#back-root-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#back-root-container div{
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: tomato;
    background-color: white;
}
#back-root-container div p{
margin-top: 5px;
margin-left: 10px;
}

@keyframes animate-side-pane {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.animate-side-pane {
  opacity: 0;
  animation: animate-side-pane;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
