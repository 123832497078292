#user-setting-root {
  margin-top: 2rem;
  margin-left: 20px;
}

#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 11px !important;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}
#profile-img-container {
  position: relative;
}

#choose-icon {
  position: absolute;
  color: white;
  background-color: var(--app-accent-pink);
  font-weight: bold;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 10px;
  right: 11px;
  bottom: 34px;
}
#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
  padding-right: 20px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}
#info-container p {
  margin-top: 3px !important;
}
#info-container h3:hover > .edit-details-svg {
  display: inline-block !important;
}
#biography-container {
  margin-top: 2rem;
}
#biography-container p {
  text-align: justify;
  color: dimgray;
  font-size: 1.2rem;
  line-height: 1.5;
}
#biography-container h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}
#biography-container h3:hover > .edit-details-svg {
  display: inline-block !important;
}

.loading-img-container {
  position: absolute;
  background-color: rgba(16, 19, 21, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 20px;
}

#edit-user-info-text {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}

.edit-user-profile-btn {
  cursor: pointer;
  color: #5dade2;
  font-weight: bold;
  font-size: 1.1rem;
}

.verified-svg {
  font-size: 1.3rem;
}
#social-media-container {
  display: flex;
  margin-top: 6px !important;
}
#social-media-container svg {
  font-size: 1.2rem;
  color: var(--app-theme);
  cursor: pointer;
  margin-right: 17px;
}


.interests-container{
  margin-top: 2rem;
}
.interests-container h3{
   font-size: 1.6rem;
  color: var(--app-theme);
}
.interests-wrapper{
  display: grid;
 grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  margin-top: 15px;
}

.interest-item{
      padding: 10px 15px;
    background-color: white;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
}

.interest-item:hover{
    background-color:#F4D03F;
}
.user-settings-wrapper-root-container{
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}
@media (max-width: 480px) {
#profile-img {
  height: 87px !important;
  width: 89px !important;

}
#choose-icon {

    height: 29px;
    width: 31px;
    right: 7px;
    bottom: 55px;
}
#info-container {
    margin-left: 5px !important;
}
#info-container h3 {
    font-size: 1rem !important;
}
.interests-wrapper{
 grid-template-columns: repeat(2, 1fr);
  row-gap: 10px;
}
.user-settings-wrapper-root-container{
  width: 100%;

}
}