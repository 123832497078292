.user-deal-details-container {
  display: flex;
  margin-top: 1rem;
}
/* yoo where did u go */
.comp-and-inv-info-container {
  width: 60%;
  margin-right: 3rem;
  padding: 2px 0px 20px 10px;
}
.inv-and-docs-container{
    padding: 15px;
    width: 30%;
}
.inv-detail-container {
  border-top: 0.7rem solid var(--app-theme);
  padding: 20px;
}

.comp-logo-name-container {
  display: flex;
}

.comp-logo-name-container img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--app-accent-pink);
}
.comp-logo-name-container div {
  margin-left: 20px;
  margin-top: 5px;
}
.comp-logo-name-container div span {
  color: #c0c0c0;
}
.comp-logo-name-container div h3 {
  color: var(--app-theme);
  font-size: 2.3rem;
  margin-top: 15px;
}

#comp-info-container p,
#inv-info-container p {
  text-align: justify;
}

#comp-info-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#inv-detail-header{
  color: #696969;
  margin-bottom: 1.5rem;
}

.invest-btn-container{
  margin-top: 2rem;
}


#info-stats-container span{
  color: grey;
  font-size: 0.8rem;
}

#info-stats-container p{
    margin-top: 8px;
    font-size: 0.9rem;
    font-weight: bold;
}

#info-stats-container{
  margin-bottom: 1rem;
}

#doc-container{
  display: flex;
  margin-top: 2rem;
}
#doc-container:hover{
cursor: pointer;
}
#doc-container svg{
  margin-right: 10px;
  font-size: 1.3rem;
}
#doc-container p{
color: #696969;
}

.doc-list-container{
  max-height: 15vh;
  overflow-y: auto;
  margin-top: 1rem;
}

.doc-list-item{
  display:flex;
  padding: 10px 15px;
}
.doc-list-item:hover{
background-color:var(--app-theme);
cursor: pointer;
color: white;
}
.doc-list-item:hover > p{
color: white !important;
}
.doc-list-item:hover > svg{
color: white !important;
}

.doc-list-item svg{
margin-right: 10px;
color: tomato;
font-size: 1.5rem;
}
.doc-list-item p{
color: grey;
}



/* docs */
#deal-docs-root{
  width: 90%;
}


#doc-svg svg{
  font-size: 2rem;
}
#doc-name-txt{
  color:blue;
}

/* feed */

#opp-detail-feed-root{
  position: relative;
  width: 90%;
}
.list-root{
  margin-top: 1.5rem;
  width: 70%;
  margin: auto;
}


/* assets */

.opp-assets-table{
  border: 1px solid whitesmoke;
  width: 95%;
  margin-top: 20px;
}



/* status codes */


.acquisition-status-root{
  position: relative;
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
}
.acquisition-info-container{
max-width: 86%;
}
.acquisition-desc-container p{
text-align: justify;
}
.receipt-upload-form-container{
  margin-top: 20px;
}

.status-root{
  padding: 15px 20px 5px 20px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}
.status-item::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
.completed-status-item::after {
  border-color: transparent #1d9d39 transparent transparent;
}
.status-root div p{
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.status-root div span{
  font-size: 0.9rem;
  color: grey;
}

.completed{
  background-color: #1d9d39;
  border: #1d9d39;
  color: white ;
}
.completed div span{
  color: white !important;
}

.status-root::before{
    content: " ";
    position: absolute;
    height: 160%;
    width: 3px;
    background: #e7ebf1;
    left: -24px;
    top: -9px;
    z-index: 1;
}

.status-root::after {
    content: " ";
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border: 2px solid #FFF;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-clip: padding-box;
    border-radius: 50%;
    background: #e7ebf1;
    left: -33px;
    top: 50%;
    margin-top: -7.5px;
}

.completed::after{
  background: #1d9d39;
}

.mobile-money-option-container ol li{
  margin-bottom: 10px;

}
.bank-option-container ol li, .bank-option-container ul li{
  margin-bottom: 10px;

}
.flex-item{
  display: flex;
}


@media (max-width: 480px) {
.user-deal-details-container {
  display: block;
}

.flex-item{
  display: block;
}
.comp-logo-name-container img {
    height: 69px;
    width: 71px;
}
.comp-logo-name-container div {
    margin-left: 9px;
}
.comp-logo-name-container div h3 {
    margin-top: -1px;
    font-size: 1.4rem;
}


.comp-and-inv-info-container {
    width: 100%;
    margin-right: 0;
}
.inv-and-docs-container {
    width: 90%;
}
.response-btns {
    padding: 11px 32px;
    font-size: 1rem;
}

.acquisition-status-root{
  display: block;
}
.acquisition-info-container{
max-width: 95%;
margin-bottom: 3rem;
}
.status-root
{
  margin-left: 30px;
}

.status-root::before {
    left: -17px;
    top: -13px;
}
.completed::after {
    background: #1d9d39;
}
.status-root::after {
    left: -27px;
    top: 50%;
    margin-top: -9.5px;
}
#opp-detail-feed-root{
  width: 100%;
}
.deal-inv-info-container{
  padding: 10px 20px 10px 2px;
}
}