.back-btn-container {
    cursor: pointer;
    background: grey;
    padding: 8px 15px;
    width: max-content;
    color: white;
    border-radius: 3px;
    margin-bottom: 10px;
}

.back-btn-container span {
    margin-left: 10px;
}