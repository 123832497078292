#loading-overlay-root{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
   background-color: rgba(16, 19, 21, 0.3);;
    display: flex;
    justify-content: center;
    align-items: center;
}
#activity-indicators-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10000;
}
#activity-indicators-container p{
margin-top: 10px;
color: var(--app-theme) !important;
font-size: 1.2rem;
}