#image-preview-overlay-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 14, 14, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#image-preview-overlay-root img{
    width: 95%;
    height: 95%;
    object-fit: contain;

}

.feeds-list-container{
     width: 70%;
     margin: auto;
    margin-top: 3rem
}

@media (max-width: 480px) {
.feeds-list-container{
     width: 100%;
}
}