#bank-item-container {
  display: flex;
  padding: 20px;
  border-radius: 10px;
}
#mobile-wallet-wrapper,
#bank-wallet-wrapper {
  margin-bottom: 2rem;
}
.wallet-icon-container {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  padding: 20px;
}
.bank-icon-container {
  height: 50px !important;
  width: 50px !important;
}
.mobile-icon-container svg {
  font-size: 1.5rem;
  color: white;
}
.bank-icon-container img {
  width: 50px;
  color: var(--app-theme);
}

#add-btn-container {
  margin-left: 1.5rem;
}
#bank-wallet-list-container,
#mobile-wallet-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 20px;
}
#bank-wallet-info-container {
  margin-top: 10px;
  width: 80%;
}

#bank-wallet-info-container div h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#bank-wallet-info-container div span {
  margin-top: 6px;
}

#account-number {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--app-theme);
  margin-top: 5px;
}
#bank-name {
  margin-bottom: 10px;
}
.account-num {
  margin-top: 1rem !important;
  font-size: 1.4rem !important;
}
#network-name {
  padding: 7px 10px 0px 10px;
  font-size: 0.8rem;
  background-color: chocolate;
  color: white;
  border-radius: 5px;
}
.mtn {
  background-color: #fbca07 !important;
}
.airtel {
  background: linear-gradient(
    to right,
    #e72427 0%,
    #e72427 50%,
    #203c79 50%,
    #203c79 100%
  );
}
.vodafone {
  background-color: #e60000 !important;
}

#action-btn-container {
  display: flex;
  margin-top: 0.7rem;
}

.space-me-right {
  margin-right: 1rem;
}

.round-me {
  padding: 7px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete {
  background-color: var(--app-theme-danger);
  color: white !important;
}
.edit {
  background-color: var(--app-clear-solid);
  color: white !important;
}

.bank-add-form {
  padding: 0 !important;
  min-height: 0px !important;
  margin-top: 1rem !important;
  width: 70vw !important;
}
.bottom-nav-styles {
  bottom: 5px !important;
  right: 50px !important;
  width: 30% !important;
  padding: 0;
  margin: auto;
}

#mobile-money-wallet-info-container , #bank-wallet-info-container{
  position: relative;
  width: 100%;
}

.more-container {
  position: absolute;
  right: -4px;
  top: -6px;
  cursor: pointer;
}
.more-container svg {
  color: grey;
}

.delete-container{
  position: absolute;
  top: -14px;
  right: 9px;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  background-color: white;
  border-radius: 5px;
padding-top: 10px;
padding-bottom: 6px;
}
.delete-container div{
/* margin-bottom: 15px; */
padding: 10px;

}
.delete-container div:hover {
background-color: var(--app-accent-pink);
color: white !important;

}
.delete-container div svg{
margin-right: 15px;

}




/* ==================== Payment Preference =========================== */

#payment-pref-root{
  margin-bottom:2rem;
}
.pref-table{
  border: 1px solid whitesmoke;
}
.table-action{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid whitesmoke;
  border-radius: 30px;
}
.table-action:hover{
background-color: #fbca07;
}


/* elections css */

.election-form-item{
  margin-bottom: 25px;
}
.buttons-container{
  display: flex;
  justify-content: flex-end;
}

.election-form-container{
  width: 90%;
  margin-left: 2rem;
  margin-top: 1rem;
}


.error-msg-container{
  display: flex;
  background-color: rgb(238, 130, 111);
  padding: 8px 15px;
  border-radius: 10px;
  width: max-content;
  margin-left: 2rem;

}
.error-msg-container-pref-page{
  display: flex;
  background-color: #85C1E9;
  padding: 8px 15px;
  border-radius: 8px;
  width: max-content;
  margin-bottom: 1.5rem;
}

.msg-icon-container{
  background-color: tomato;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container-pref{
  background-color: #3498DB;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container svg{
font-size: 0.8rem;

}
.msg-text-container{
  color: white;
  margin-left: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
#bank-wallet-list-container,
#mobile-wallet-list-container {
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

.icon-container {
  height: 3px;
  width: 4px;
}
.mobile-icon-container svg {
  font-size: 1rem;
}
.delete-container {
  width: 100%;
}
.bank-icon-container {
    height: 13px !important;
    width: 15px !important;
}
#bank-wallet-info-container div h3 {
    font-size: 1.1rem;
}
.account-num {
    margin-top: 1rem !important;
    font-size: 1rem !important;
}
}