#feed-detail-logo-container img{
border-radius: 10px;
height: 110px;
width: 110px;
margin-right: 15px;
object-position: center;
object-fit: cover;
border: 4px solid var(--app-accent-pink);
}
.feed-detail-root{
    width: 90%;
    margin: auto;
    margin-top: 1rem;
}
#feed-title-and-body-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 95%;
}

.feed-detail-body-container p{
line-height: 1.7;
text-align: justify;
font-size: 1.1rem;
margin-bottom: 20px;
}

.comments-list-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* padding: 15px 20px 0px 20px; */
    border-radius: 4px;
    border: 1px solid #ccc;
    /* max-height: 40vh;
    overflow-y: auto; */
    
}
#commenter-img-container img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:  2px solid rgb(138, 44, 44);
}

#commenter-info{
    margin-left:10px;
    margin-top: 5px;
}

#commenter-info small{
margin-left: 5px;
}

#comment-body{
margin-top: 10px;
}
#feed-detail-body-images img{
    width:100%;
    /* object-fit: cover; */
    height:65vh;
    /* height: 100%; */
    border-radius:10px;
}
#feed-detail-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:65vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#feed-detail-body-images{
 margin-top: 20px;
}

.comment-item-container{
padding-bottom: 10px;
border-bottom: 1px solid #ccc;
padding:10px;
/* margin-top: 10px; */
cursor: pointer;

}
.comment-item-container:hover{
background-color:whitesmoke;

}

#feed-detail-title-container{
    margin-bottom: 1rem;
    text-align: center;


}
#feed-detail-title-container h1{
color: var(--app-theme);
cursor: pointer;
}

.comments-header{
    margin-top: 10px;
    margin-left: 20px;
}

.feed-detail-body p{
    text-align: justify !important;
    font-size: 1.4rem !important;
}

@media (max-width: 480px) {
#feed-detail-logo-container img{
height: 63px;
width: 68px;
}
.feed-detail-root{
    margin-top: 0rem;
}
.feed-detail-body p{
    font-size: 1rem !important;
}
}