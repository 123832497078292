.user-deal-items-wrapper {
  margin-top: 1rem;
}
.user-deal-items-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 30px;
  margin-bottom: 2rem;
}
.deal-item-contain {
  margin-right: 20px;
  border-radius: 20px;
}
.deal-item-contain img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 150px;
}
.deal-info-container {
  padding: 2px 20px 20px 20px;
}
.deal-info-container h3 {
  font-size: 1rem;
}

.opp-info-items-container {
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.pps-container,
.shares-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pps-container span {
  color: var(--toast-success-color);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.shares-container span {
  color: var(--app-theme);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.deal-item-contain:hover {
  transform: scale(1.02);
  transition: 0.3s ease-out;
}

.deal-item-contain:active {
  transform: scale(1.01);
  transition: 0.2s ease-out;
}

#no-deals-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 70vh;
}

.response-btns {
  padding: 15px 35px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  width: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
.response-btns svg {
  margin-right: 8px;
  margin-bottom: 5px;
}

.accept-item {
  color: white;
  background-color: var(--toast-success-color) !important;
}
.accept-item:hover {
  color: white;
  background-color: green !important;
}

.reject {
  border: 1px solid tomato;
  color: tomato;
}
.flex-me {
  display: flex;
  justify-content: space-between;
}
.stats-root {
  margin-bottom: 3rem;
}
.money-section {
  position: absolute;
  top: -20px;
  right: 10px;
}

/* stats */
.partnership-opp-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.opp-valuation h1 {
  font-size: 3rem;
  color: var(--app-theme);
}
.deals-wrapper-root {
  width: 90%;
  margin: auto;
}

.space-me-right {
  margin-right: 2rem;
}
.partner-arrow {
  color: grey;
  margin-top: 15px;
}

.no_item_found_display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.deal-image-container img {
  height: 90px;
  width: 120px;
  border: 2px solid var(--app-accent-pink);
  border-radius: 10px;
  object-fit: cover;
  margin-right: 15px;
}
.deal-image-container {
  margin-bottom: 10px;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .user-deal-items-wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    margin-bottom: 2rem;
  }
  .deals-wrapper-root {
    width: 95%;
  }
  .stats-root {
    display: block;
  }

  .partnership-opp-name {
    margin-bottom: 1.6rem;
    align-items: flex-start;
  }

  .opp-valuation h1,
  .right-of-title-box h1 {
    font-size: 1.7rem;
    color: var(--app-theme);
  }

  .vanish {
    display: none;
  }

  .right-of-title-box {
    padding-right: 0px;
    display: block;
  }
  .no_item_found_display {
    height: 40vh;
  }
}
