@import url(https://fonts.googleapis.com/css?family=Google+Sans:200,300,400,400i,500,600,700,900);
:root {
  --elevate-float: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  --elevate-half: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  --elevate-1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  --elevate-1-half: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  --elevate-2: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --elevate-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  --elevate-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  --elevate-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);

  --elevate-float-2: 0px 4px 42px -12px rgba(0, 0, 0, 0.09);
}
.elevate-float {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--elevate-float);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: var(--elevate-float);
}
.elevate-half {
  box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  box-shadow: var(--elevate-half);
  -webkit-box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  -webkit-box-shadow: var(--elevate-half);
}
.elevate-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: var(--elevate-1);
}
.elevate-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: var(--elevate-1-half);
}
.elevate-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-2);
}

.elevate-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-3);
}

.elevate-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: var(--elevate-4);
}

.elevate-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  box-shadow: var(--elevate-5);
}
.elevate-float-2 {
  box-shadow: 0px 4px 42px -12px rgba(0, 0, 0, 0.09);
  box-shadow: var(--elevate-float-2);
}

@media only screen and (max-width: 767px) {
  .vanish-on-phone {
    display: none !important;
  }

  .footer-bottom-panel {
    bottom: -70px;
  }

  .footer-bottom-panel p {
    font-size: 0.8rem;
    text-align: center;
  }

  .row {
    margin: 0px !important;
  }

  .footer-link {
    display: inline;
    margin-right: 4px;
    font-size: 0.8rem;
  }

  .footer-column-title {
    font-size: 0.9rem;
  }

  .footer-container {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) {
  .vanish-on-pc {
    display: none !important;
  }

 
}

@-webkit-keyframes scale-and-fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9) translateY(70px);
            transform: scale(0.9) translateY(70px);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
  }
}

@keyframes scale-and-fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9) translateY(70px);
            transform: scale(0.9) translateY(70px);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
  }
}

.scale-and-fade-in {
  opacity: 0;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation: scale-and-fade-in 0.6s forwards;
          animation: scale-and-fade-in 0.6s forwards;
}



:root {
  --default-font-family: "Google Sans", sans-serif;
}

html {
  font-family: "Google Sans", sans-serif;
  font-family: var(--default-font-family);
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.put-me-inline {
  display: inline-block;
}

.everyday-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-toggler {
  color: var(--app-theme-success) !important;
  margin-left: 6px;
  cursor: pointer;
}

.text-toggler:hover {
  color: var(--app-accent-pink) !important;
  transition: 0.2s ease-out;
}

.round-action-btns {
  background-color: var(--action-color);
  margin-left: 15px;
}

.navigation-btn{
  padding: 10px 20px !important;
}
.navigation-btn svg{
  padding-bottom: 10px;
}

table{
  width: 100%;
}
.error-page-container {
  height: 100vh;
  width: 100%;
  background: #daceda;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden !important;
}

.more-info {
  color: #833e83;
  text-decoration: none;
}

.close-error-log {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
  color: #833e83;
  padding: 0.5rem;
  background-color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 0.8rem;
}

.close-error-log:hover {
  box-shadow: var(--elevate-1);
  transition: 0.2s ease-out;
}
.error-log {
  position: absolute;
  background-color: #833e83;
  color: white;

  left: 0;
  width: 20vw;
  height: 100vh;

  display: grid;
  place-items: center;

  -webkit-animation: slide-right 0.1s linear;

          animation: slide-right 0.1s linear;
}

.error-report {
  padding: 2rem;
}

#oops:target {
  display: grid;
}

#oops {
  display: none;
}

@media screen and (min-width: 560px) {
  .error-log {
    left: 0;
    width: 20vw;
    height: 100vh;

    -webkit-animation: slide-right 0.1s linear;

            animation: slide-right 0.1s linear;
  }
}

@-webkit-keyframes slide-right {
  from {
    left: -20vw;
  }
  to {
    left: 0vw;
  }
}

@keyframes slide-right {
  from {
    left: -20vw;
  }
  to {
    left: 0vw;
  }
}

.title-box {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
}

.title-box h1 {
  color: var(--app-theme-lighter);
}
.title-box p {
  color: grey;
  margin-top: 8px;
}

.login-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    width: 10vw;
    margin-top: 3rem;
    border-radius: 7px;
    background-color: var(--app-theme);
    color: white;
    cursor: pointer;
    border: 1px solid var(--app-theme);
}
.login-btn-container:hover{
    color: var(--app-theme);
    background-color: white;
}

@media (max-width: 480px) {
    .login-btn-container{
    width: 50vw;
}
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #f5eaff;
  opacity: 0;
  -webkit-animation: 0.4s load-in forwards;
          animation: 0.4s load-in forwards;
}

@-webkit-keyframes load-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes load-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.response-wrapper{
    padding: 20px 40px;
   border-top-right-radius: 0px;
   border-top-left-radius: 60px;
   border-bottom-right-radius: 60px;
   border-bottom-left-radius: 0px;
}


#response-btns-container{
    display: flex;
    justify-content: space-evenly;
}

#response-intro-container{
    margin-top: 1.6rem;
    margin-bottom: 2rem;
}

#res-about-container{
      margin-bottom: 3rem;
}
#res-about-container p{
     text-align: justify;
}
#res-name-section{
    margin-top: 2rem;
}

.opp-response-main-container{
    width: 50vw;
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.invitation-not-found-message-container{
    padding: 10px 20px;
    border-radius: 10px;
    width: 60%;
}

.message-body-container{
    margin-top: 15px;
    text-align: justify;
}
.message-body-container span{
    color: rgb(61, 60, 60);
    /* font-size: 1rem; */
}
.message-body-container p{
     margin-top: 15px;
    font-size: 1.1rem;
    text-align: right;
    font-weight: bold;
   color: rgb(61, 60, 60);
}
.response-wrapper {
    max-height: 80vh;
    overflow-y: scroll;
}


@media (max-width: 480px) {
.opp-response-main-container {
    width: 95vw;
}
.response-wrapper {
    padding: 20px 15px;
}
.invitation-not-found-message-container{
    width: 80%;
}
}
.k-title {
  color: var(--app-theme-lighter);
  margin-bottom: 12px;
}

#loading-overlay-root{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
   background-color: rgba(16, 19, 21, 0.3);;
    display: flex;
    justify-content: center;
    align-items: center;
}
#activity-indicators-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10000;
}
#activity-indicators-container p{
margin-top: 10px;
color: var(--app-theme) !important;
font-size: 1.2rem;
}
.i-am-money {
  color: var(--toast-success-color) !important;
  font-weight: bold;
}

.all-pages-root-wrapper {
  margin: auto;
  margin-top: 1rem;
}
/* ======= end of Generic css ======== */
#user-dash-intro {
  display: flex;
  justify-content: space-between;
}
#intro-stats-container {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

#intro-txt-container {
  width: 40%;
}
#intro-txt-container p {
  font-size: 2rem;
  font-weight: bold;
}
#intro-txt-container span {
  font-size: 0.9rem;
  color: grey;
}

#intro-stats-container div  {
font-size: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
#stat-item-container p {
  font-size: 3rem;
  font-weight: bold;
  color: var(--app-theme);
}
#stat-item-container span {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--app-theme);
  text-align: right !important;
}
.move-down-small {
      margin-right: 20px;
}

.profit-items {
  color: var(--toast-success-color) !important;
}
/* ============================ end of intro css ============= */

.dash-card-container {
  padding: 15px;
  border-radius: 20px;
  /* width: 95%; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.dash-card-item {
  padding: 10px;
  display: flex;
}

.dash-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-accent-pink);
  border-radius: 10px;
  height: 55px;
  width: 55px;
  margin-right: 20px;
}
.dash-card-icon-container svg {
  color: white;
  font-size: 2rem;
}
.dash-card-txt-container p {
  font-size: 1rem;
  color: grey;
}
.dash-card-txt-container span {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--app-theme);
}

/* ========== end of cards css ================ */
.dashboard-deals-invites-container {
  margin-top: 2rem;
}
.deals-lists {
  height: -webkit-max-content;
  height: max-content;
  /* width: 95%; */
  margin-bottom: 15px;
}
.invitation-list {
  /* width: 95%; */
  height: -webkit-max-content;
  height: max-content;
}
.dash-invites-container {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.top-investors-container {
  padding: 20px;
  border-radius: 10px;
}

.dashboard-transaction-bar-container {
  margin-top: 1.5rem;
}
.right-of-title-box {
  flex-direction: column;
  align-items: flex-end;
  padding-right: 70px;
}

.right-of-title-box h6 {
  color: var(--app-money-green);
}

.right-of-title-box h1 {
  font-size: 3rem;
  color: var(--app-money-green);
}
.right-of-title-box h2 {
  color: var(--app-theme-success);
}

.accept-txt {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept:hover {
  background-color: var(--app-theme-success);
  color: white;
}
.reject:hover {
  background-color: tomato;
  color: white;
}
.view:hover {
  background-color: var(--app-accent-pink);
  color: white;
}

#actions {
  display: flex;
}
.accept {
  margin-right: 7px;
}
.view {
  margin-right: 7px;
}
.computing{
  font-size: 1rem !important;
}

.set-payment-pref-container {
  position: relative;
  padding: 20px;
  /* width: 95%; */
  border-radius: 10px;
  margin-top: 30px;
}

.set-payment-close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: tomato;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-payment-close-btn:hover {
  background-color: tomato;
  color: white;
}

#info-icon-container svg {
  margin-right: 15px;
  font-size: 2rem;
  color: maroon;
}

#notification-body-container span {
  color: maroon;
  font-weight: bold;
  font-size: 1.2rem;
}
#notification-body-container p {
  margin-top: 5px;
}
#notification-body-container p a{
  text-decoration: none;
  font-weight: bold;
}


@media (max-width: 480px) {
  #intro-txt-container p {
  font-size: 1rem;
}
.dash-card-icon-container {
    height: 45px;
    width: 45px;
    margin-right: 5px;
}
.dash-card-icon-container svg {
  font-size: 1rem;
}
.dash-card-txt-container span {
    font-size: 1rem;
}
.dash-card-container {
    width: 100%;
    display: block;
    padding: 0;
}
.mb{
  margin-bottom: 10px;
}

#intro-stats-container div svg {
    display: none;
    
}
#intro-stats-container, #user-dash-intro {
    display: block;
}
#intro-stats-container {
    width: 100%;
    position: relative;
}
#stat-item-container p {
    font-size: 1.4rem;
}
#intro-txt-container {
    width: 100%;
}
.dash-card-item {
border-radius: 10px;
}
#intro-stats-container div {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 20px;
}
}
#more-options-root {
 display: flex;
justify-content: flex-end;

}

#more-options-container {
  display: flex;
  margin-right: 20px;
}

#more-option-item {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;
  margin-right: 10px;
  -webkit-animation: slide_in 0.3s ease-in-out  forwards;
          animation: slide_in 0.3s ease-in-out  forwards;
}

#more-icon-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
#more-option-item:hover,
#more-icon-container:hover {
  color: white;
  background-color: var(--app-accent-pink);
}

.cancel:hover {
  background-color: tomato;
  color: white !important;
}


.view-more-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
}



@-webkit-keyframes slide_in {
  0% {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}



@keyframes slide_in {
  0% {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
#com-image-container img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  border: 3px solid var(--app-accent-pink);
}

#image-and-verify-container {
  display: flex;
  padding: 20px;
}

#company-name-container{
    margin-top: 10px;
}
#company-name-container span{
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--app-theme);
    margin-left: 10px;
}
#invest-card-wrapper {
  border: 1px solid whitesmoke;
}
#badge-root-wrapper{
  margin-top: 10px;
  margin-bottom: 5px;
}
#learn-more-txt-container {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#learn-more-txt-container span{
  color: #0645AD ;
  cursor: pointer;
  font-weight: bold;
}

#name-and-desc-container,
#deal-money-container {
  padding-left: 15px;
  padding-right: 15px;
}
#name-and-desc-container h3 {
  margin-bottom: 15px;
  font-size: 1.1rem;
}
#name-and-desc-container p {
  font-size: 0.9rem;
  text-align: justify;
}


#deal-money-container {
  margin-top: 1.5rem;
}

#subscriptions-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
#subscriptions-container div span, #notable-investors-container span, #return-percentages-container div span{
  color: grey;
  font-size: 0.8rem;
}
#notable-investors-container p {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 8px;
}

#notable-investors-container{
    margin-bottom: 1.5rem;
}

#return-percentages-container{
    display: flex;
    justify-content: space-between;
}
#return-percentages-container div p{
    margin-top: 8px;
}



.invest-card-wrapper:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.invest-card-wrapper:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}

#footer-btns-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
border-top: 1px solid whitesmoke;
}

#view-more-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  20px;
  color: var(--app-theme);
  background-color: white;
  /* width: 30%; */
  cursor: pointer;
  /* border: 1px solid var(--app-theme); */
}
#view-more-btn-container svg{
  font-size: 1rem;
  margin-bottom: 5px;
  margin-left: 8px;
}
#view-more-btn-container:hover{
  /* background-color: var(--app-theme); */
  color:var(--app-accent-pink);

}

.inv-card-badge-container{
  padding: 10px 20px;
  background-color: rgb(235 255 235);
  color: #1d5213;
}
.inv-card-badge-container p{
  margin: 0;
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
}

.pending{
  background-color: rgb(213 238 255);
  color: #0f4768 !important;
   
}
.pending p{
/* color: white !important; */
}

.user-deal-items-wrapper {
  margin-top: 1rem;
}
.user-deal-items-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 30px;
  row-gap: 30px;
  margin-bottom: 2rem;
}
.deal-item-contain {
  margin-right: 20px;
  border-radius: 20px;
}
.deal-item-contain img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 150px;
}
.deal-info-container {
  padding: 2px 20px 20px 20px;
}
.deal-info-container h3 {
  font-size: 1rem;
}

.opp-info-items-container {
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.pps-container,
.shares-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pps-container span {
  color: var(--toast-success-color);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.shares-container span {
  color: var(--app-theme);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.deal-item-contain:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.deal-item-contain:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}

#no-deals-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 70vh;
}

.response-btns {
  padding: 15px 35px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  width: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
.response-btns svg {
  margin-right: 8px;
  margin-bottom: 5px;
}

.accept-item {
  color: white;
  background-color: var(--toast-success-color) !important;
}
.accept-item:hover {
  color: white;
  background-color: green !important;
}

.reject {
  border: 1px solid tomato;
  color: tomato;
}
.flex-me {
  display: flex;
  justify-content: space-between;
}
.stats-root {
  margin-bottom: 3rem;
}
.money-section {
  position: absolute;
  top: -20px;
  right: 10px;
}

/* stats */
.partnership-opp-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.opp-valuation h1 {
  font-size: 3rem;
  color: var(--app-theme);
}
.deals-wrapper-root {
  width: 90%;
  margin: auto;
}

.space-me-right {
  margin-right: 2rem;
}
.partner-arrow {
  color: grey;
  margin-top: 15px;
}

.no_item_found_display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.deal-image-container img {
  height: 90px;
  width: 120px;
  border: 2px solid var(--app-accent-pink);
  border-radius: 10px;
  object-fit: cover;
  margin-right: 15px;
}
.deal-image-container {
  margin-bottom: 10px;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .user-deal-items-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-bottom: 2rem;
  }
  .deals-wrapper-root {
    width: 95%;
  }
  .stats-root {
    display: block;
  }

  .partnership-opp-name {
    margin-bottom: 1.6rem;
    align-items: flex-start;
  }

  .opp-valuation h1,
  .right-of-title-box h1 {
    font-size: 1.7rem;
    color: var(--app-theme);
  }

  .vanish {
    display: none;
  }

  .right-of-title-box {
    padding-right: 0px;
    display: block;
  }
  .no_item_found_display {
    height: 40vh;
  }
}

.search-bar-root-container {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex: 12 1;
  flex-direction: row;
  box-sizing: border-box;
  background: white;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.search-area {
  flex: 10 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}


.search-input {
  border: 0px !important;
  width: 50vw !important;
  padding: 10px;
  margin: 0px !important;
 width: 58%;
 box-sizing:border-box;
}
.filter-btn {
  display: flex;
  border: 1px solid whitesmoke;
  padding: 8px 10px;
  border-radius: 20px;
  background-color: #fef8ff;
  cursor: pointer;
}
.filter-btn small {
  color: var(--app-accent-pink);
  margin-left: 5px;
  font-weight: bold;
}

.filter-btn svg {
  color: var(--app-accent-pink);
  cursor: pointer;
  font-size: 0.8rem;
}



@-webkit-keyframes show-down {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}



@keyframes show-down {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}


.filter-field-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
 margin-top: 15px;
 -webkit-animation: show-down;
         animation: show-down;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.search-filter-checkboxes .checkbox-empty-box {
  height: 15px;
  width: 15px;
  border: solid 4px var(--app-theme-lighter);
  margin: 4px;
  border-radius: 2px;
}

@media (max-width: 480px) {
.search-input {
  width: 40vw !important;
  font-size: 0.8rem;
  padding: 0px;
}
.filter-btn{
  padding: 5px 7px;
}
.filter-field-container {
    grid-template-columns: repeat(3, 1fr);
}
.search-filter-checkboxes .checkbox-default-container {
    padding: 1px;
}

.search-filter-checkboxes .checkbox-empty-box {
    height: 8px !important;
    width: 8px !important;
}


.search-filter-checkboxes .checkbox-default-container p {
    font-size: 0.7rem;
}

.search-filter-checkboxes .hovering-checkmark {
  font-size: 0.7rem;
  font-weight: bold;
}
}
#bank-item-container {
  display: flex;
  padding: 20px;
  border-radius: 10px;
}
#mobile-wallet-wrapper,
#bank-wallet-wrapper {
  margin-bottom: 2rem;
}
.wallet-icon-container {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  padding: 20px;
}
.bank-icon-container {
  height: 50px !important;
  width: 50px !important;
}
.mobile-icon-container svg {
  font-size: 1.5rem;
  color: white;
}
.bank-icon-container img {
  width: 50px;
  color: var(--app-theme);
}

#add-btn-container {
  margin-left: 1.5rem;
}
#bank-wallet-list-container,
#mobile-wallet-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  gap: 40px;
  margin-top: 20px;
}
#bank-wallet-info-container {
  margin-top: 10px;
  width: 80%;
}

#bank-wallet-info-container div h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#bank-wallet-info-container div span {
  margin-top: 6px;
}

#account-number {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--app-theme);
  margin-top: 5px;
}
#bank-name {
  margin-bottom: 10px;
}
.account-num {
  margin-top: 1rem !important;
  font-size: 1.4rem !important;
}
#network-name {
  padding: 7px 10px 0px 10px;
  font-size: 0.8rem;
  background-color: chocolate;
  color: white;
  border-radius: 5px;
}
.mtn {
  background-color: #fbca07 !important;
}
.airtel {
  background: linear-gradient(
    to right,
    #e72427 0%,
    #e72427 50%,
    #203c79 50%,
    #203c79 100%
  );
}
.vodafone {
  background-color: #e60000 !important;
}

#action-btn-container {
  display: flex;
  margin-top: 0.7rem;
}

.space-me-right {
  margin-right: 1rem;
}

.round-me {
  padding: 7px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete {
  background-color: var(--app-theme-danger);
  color: white !important;
}
.edit {
  background-color: var(--app-clear-solid);
  color: white !important;
}

.bank-add-form {
  padding: 0 !important;
  min-height: 0px !important;
  margin-top: 1rem !important;
  width: 70vw !important;
}
.bottom-nav-styles {
  bottom: 5px !important;
  right: 50px !important;
  width: 30% !important;
  padding: 0;
  margin: auto;
}

#mobile-money-wallet-info-container , #bank-wallet-info-container{
  position: relative;
  width: 100%;
}

.more-container {
  position: absolute;
  right: -4px;
  top: -6px;
  cursor: pointer;
}
.more-container svg {
  color: grey;
}

.delete-container{
  position: absolute;
  top: -14px;
  right: 9px;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  background-color: white;
  border-radius: 5px;
padding-top: 10px;
padding-bottom: 6px;
}
.delete-container div{
/* margin-bottom: 15px; */
padding: 10px;

}
.delete-container div:hover {
background-color: var(--app-accent-pink);
color: white !important;

}
.delete-container div svg{
margin-right: 15px;

}




/* ==================== Payment Preference =========================== */

#payment-pref-root{
  margin-bottom:2rem;
}
.pref-table{
  border: 1px solid whitesmoke;
}
.table-action{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid whitesmoke;
  border-radius: 30px;
}
.table-action:hover{
background-color: #fbca07;
}


/* elections css */

.election-form-item{
  margin-bottom: 25px;
}
.buttons-container{
  display: flex;
  justify-content: flex-end;
}

.election-form-container{
  width: 90%;
  margin-left: 2rem;
  margin-top: 1rem;
}


.error-msg-container{
  display: flex;
  background-color: rgb(238, 130, 111);
  padding: 8px 15px;
  border-radius: 10px;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 2rem;

}
.error-msg-container-pref-page{
  display: flex;
  background-color: #85C1E9;
  padding: 8px 15px;
  border-radius: 8px;
  width: -webkit-max-content;
  width: max-content;
  margin-bottom: 1.5rem;
}

.msg-icon-container{
  background-color: tomato;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container-pref{
  background-color: #3498DB;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container svg{
font-size: 0.8rem;

}
.msg-text-container{
  color: white;
  margin-left: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
#bank-wallet-list-container,
#mobile-wallet-list-container {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  gap: 16px;
}

.icon-container {
  height: 3px;
  width: 4px;
}
.mobile-icon-container svg {
  font-size: 1rem;
}
.delete-container {
  width: 100%;
}
.bank-icon-container {
    height: 13px !important;
    width: 15px !important;
}
#bank-wallet-info-container div h3 {
    font-size: 1.1rem;
}
.account-num {
    margin-top: 1rem !important;
    font-size: 1rem !important;
}
}
.verify-item span{
font-size: 0.9rem;
color: grey;
}


.verify-item p{
font-size:1.2rem;
color: black;
font-weight: bold;
}

.verify-item{
    margin-bottom: 15px;
}

.bank-user-info{
    /* display: flex; */
    justify-content: space-evenly;
}

.verify-bank-wrapper{
display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap:15px;
  row-gap:15px;
}
.root-wrapper {
  display: grid;
  grid-template-columns: var(--side-ratio) auto;
  width: 100%;
  height: 100vh;
}

.children-container {
  position: relative;
  margin-top: 2rem;
}

.side-pane {
  position: absolute;
  top: 61px;
  right: 0px;
  width: 300px;
  height: 100vh;
  background: white;
}

#back-root-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#back-root-container div{
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: tomato;
    background-color: white;
}
#back-root-container div p{
margin-top: 5px;
margin-left: 10px;
}

@-webkit-keyframes animate-side-pane {
  from {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes animate-side-pane {
  from {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.animate-side-pane {
  opacity: 0;
  -webkit-animation: animate-side-pane;
          animation: animate-side-pane;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.distributions-table {
border: 1px solid whitesmoke;
}
#receipt-file svg{
    font-size: 1.5rem;
    color: #C0392B ;
    text-align: center;
}
#image-preview-overlay-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 14, 14, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#image-preview-overlay-root img{
    width: 95%;
    height: 95%;
    object-fit: contain;

}

.feeds-list-container{
     width: 70%;
     margin: auto;
    margin-top: 3rem
}

@media (max-width: 480px) {
.feeds-list-container{
     width: 100%;
}
}
.feed-card-root{
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}
#feed-card-logo-and-name-container{
    display:flex;
}

#feed-card-logo-container img{
width: 60px;
height: 60px;
border-radius: 100%;
object-position: center;
object-fit: cover;
border: 3px solid var(--app-accent-pink);

}
#feed-card-logo-container {
    margin-right: 10px;
}

#feed-card-opp-det p{
    margin-top: 5px;
}

#feed-card-title-container{
    /* padding: 0px 20px; */
    margin-top: 20px;
}

.feed-card-body-container{
    /* padding: 5px 20px; */
    margin-bottom: 1rem;
}
.feed-card-body-container p{
    text-align: justify;
}

#feed-card-footer-container{
    display: flex;
justify-content: flex-end;
margin-top: 10px;
}
.comment-btn-container{
    float: right;
    padding: 15px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    margin-right: 20px;
    cursor: pointer;
    border: 2px solid whitesmoke;
}
.comment-btn-container:hover{
background-color: #F4D03F;
/* color: white; */
}
.comment-btn-container span{
margin-left: 10px;
}
.comment-btn-container svg{
/* color: whitesmoke; */
font-size: 1rem;
color: grey;
}
#feed-card-body-images img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
}
#feed-card-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:55vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#feed-card-body-images{
 margin-top: 20px;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: rgb(214, 213, 213);
}
.feed-card-body-container{
     width: 90%;
  
     cursor: pointer
}
.feed-card-body{
   margin-left:4.6rem;
}

@media (max-width: 480px) {
#feed-card-logo-container img{
width: 39px;
height: 39px;

}
#feed-card-body-images img{
    height: 100%;
}
.feed-card-body-container{
     margin-left:0rem;
     width: 100%;
}
}
.distribution-detail-root{
        width: 90%;
    margin: auto;
    margin-top: 1rem;

}
.top-card{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    border-radius: 10px;
    width: 90%;
}
.distribution-info-container{
    display: flex;
    justify-content: space-between;
        width:50%;
        border-radius: 10px;
}
.received-money-container{
    display:flex;
    color: grey;
    margin-bottom: 10px;
    margin-top: 15px;
}
.received-money-container h2{
    font-size: 3rem;
    color: var(--app-money-green);
}
.received-money-container p {
    font-size: 1.5rem;
    margin-top: 10px;

}

.total-received-title{
    font-size: 1.2rem;
    color: grey;
    font-weight: bold;
}

.pending{
    color: #5DADE2 !important;
}

.distribution-note-container{
    margin-top: 2rem;
    width: 95%;
}
.distribution-note-container p{
    text-align: justify;
}

#distribution-company-det h2{
font-size: 1.8rem;
color: var(--app-Theme);
}

.payment-distribution-container{
    margin-top: 2rem;
}

#comment-file-container{
    margin-top: 10px;
}
#comment-file-container img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
      border:  1px solid whitesmoke;
}
#comment-file-container video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:45vh;
    border-radius:10px;
    border:  1px solid whitesmoke;
}
#comment-body{
    margin-left:60px;
    width: 90%;
}

.comment-file-container{
    display: flex;
    justify-content: center;
align-items: center;
flex-direction: column;
}
.comment-file-container p{
color: grey;
margin-top: 10px;
}

.file-wrapper{
    padding: 30px 40px;
}
.file-wrapper svg{
   font-size: 10rem;
}

.distribution-receipt-container{
    margin-top: 2rem;
    margin-bottom: 4rem;
}
.back-btn-container {
    cursor: pointer;
    background: grey;
    padding: 8px 15px;
    width: -webkit-max-content;
    width: max-content;
    color: white;
    border-radius: 3px;
    margin-bottom: 10px;
}

.back-btn-container span {
    margin-left: 10px;
}
.user-deal-details-container {
  display: flex;
  margin-top: 1rem;
}
/* yoo where did u go */
.comp-and-inv-info-container {
  width: 60%;
  margin-right: 3rem;
  padding: 2px 0px 20px 10px;
}
.inv-and-docs-container{
    padding: 15px;
    width: 30%;
}
.inv-detail-container {
  border-top: 0.7rem solid var(--app-theme);
  padding: 20px;
}

.comp-logo-name-container {
  display: flex;
}

.comp-logo-name-container img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--app-accent-pink);
}
.comp-logo-name-container div {
  margin-left: 20px;
  margin-top: 5px;
}
.comp-logo-name-container div span {
  color: #c0c0c0;
}
.comp-logo-name-container div h3 {
  color: var(--app-theme);
  font-size: 2.3rem;
  margin-top: 15px;
}

#comp-info-container p,
#inv-info-container p {
  text-align: justify;
}

#comp-info-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#inv-detail-header{
  color: #696969;
  margin-bottom: 1.5rem;
}

.invest-btn-container{
  margin-top: 2rem;
}


#info-stats-container span{
  color: grey;
  font-size: 0.8rem;
}

#info-stats-container p{
    margin-top: 8px;
    font-size: 0.9rem;
    font-weight: bold;
}

#info-stats-container{
  margin-bottom: 1rem;
}

#doc-container{
  display: flex;
  margin-top: 2rem;
}
#doc-container:hover{
cursor: pointer;
}
#doc-container svg{
  margin-right: 10px;
  font-size: 1.3rem;
}
#doc-container p{
color: #696969;
}

.doc-list-container{
  max-height: 15vh;
  overflow-y: auto;
  margin-top: 1rem;
}

.doc-list-item{
  display:flex;
  padding: 10px 15px;
}
.doc-list-item:hover{
background-color:var(--app-theme);
cursor: pointer;
color: white;
}
.doc-list-item:hover > p{
color: white !important;
}
.doc-list-item:hover > svg{
color: white !important;
}

.doc-list-item svg{
margin-right: 10px;
color: tomato;
font-size: 1.5rem;
}
.doc-list-item p{
color: grey;
}



/* docs */
#deal-docs-root{
  width: 90%;
}


#doc-svg svg{
  font-size: 2rem;
}
#doc-name-txt{
  color:blue;
}

/* feed */

#opp-detail-feed-root{
  position: relative;
  width: 90%;
}
.list-root{
  margin-top: 1.5rem;
  width: 70%;
  margin: auto;
}


/* assets */

.opp-assets-table{
  border: 1px solid whitesmoke;
  width: 95%;
  margin-top: 20px;
}



/* status codes */


.acquisition-status-root{
  position: relative;
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
}
.acquisition-info-container{
max-width: 86%;
}
.acquisition-desc-container p{
text-align: justify;
}
.receipt-upload-form-container{
  margin-top: 20px;
}

.status-root{
  padding: 15px 20px 5px 20px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}
.status-item::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
.completed-status-item::after {
  border-color: transparent #1d9d39 transparent transparent;
}
.status-root div p{
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.status-root div span{
  font-size: 0.9rem;
  color: grey;
}

.completed{
  background-color: #1d9d39;
  border: #1d9d39;
  color: white ;
}
.completed div span{
  color: white !important;
}

.status-root::before{
    content: " ";
    position: absolute;
    height: 160%;
    width: 3px;
    background: #e7ebf1;
    left: -24px;
    top: -9px;
    z-index: 1;
}

.status-root::after {
    content: " ";
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border: 2px solid #FFF;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-clip: padding-box;
    border-radius: 50%;
    background: #e7ebf1;
    left: -33px;
    top: 50%;
    margin-top: -7.5px;
}

.completed::after{
  background: #1d9d39;
}

.mobile-money-option-container ol li{
  margin-bottom: 10px;

}
.bank-option-container ol li, .bank-option-container ul li{
  margin-bottom: 10px;

}
.flex-item{
  display: flex;
}


@media (max-width: 480px) {
.user-deal-details-container {
  display: block;
}

.flex-item{
  display: block;
}
.comp-logo-name-container img {
    height: 69px;
    width: 71px;
}
.comp-logo-name-container div {
    margin-left: 9px;
}
.comp-logo-name-container div h3 {
    margin-top: -1px;
    font-size: 1.4rem;
}


.comp-and-inv-info-container {
    width: 100%;
    margin-right: 0;
}
.inv-and-docs-container {
    width: 90%;
}
.response-btns {
    padding: 11px 32px;
    font-size: 1rem;
}

.acquisition-status-root{
  display: block;
}
.acquisition-info-container{
max-width: 95%;
margin-bottom: 3rem;
}
.status-root
{
  margin-left: 30px;
}

.status-root::before {
    left: -17px;
    top: -13px;
}
.completed::after {
    background: #1d9d39;
}
.status-root::after {
    left: -27px;
    top: 50%;
    margin-top: -9.5px;
}
#opp-detail-feed-root{
  width: 100%;
}
.deal-inv-info-container{
  padding: 10px 20px 10px 2px;
}
}
.tc-root{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.header-container{
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
}

.header-container span{
  color: grey;
}
.header-container p{
 font-weight: bold;
 font-size: 2.4rem;
 margin-top: 10px;
}

.tc-body p{
text-align: justify;
margin-bottom: 1rem;
line-height: 1.8rem;
}

.tc-btn-container{
    display: flex;

}

.btn-item{
    padding: 15px 35px;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.accept-item{
    color: white;
    background-color: #3f0f3f;
}
.accept-item:hover{
    color: white;
    background-color: var(--toast-success-color);
}
#user-setting-root {
  margin-top: 2rem;
  margin-left: 20px;
}

#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 11px !important;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}
#profile-img-container {
  position: relative;
}

#choose-icon {
  position: absolute;
  color: white;
  background-color: var(--app-accent-pink);
  font-weight: bold;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 10px;
  right: 11px;
  bottom: 34px;
}
#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
  padding-right: 20px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}
#info-container p {
  margin-top: 3px !important;
}
#info-container h3:hover > .edit-details-svg {
  display: inline-block !important;
}
#biography-container {
  margin-top: 2rem;
}
#biography-container p {
  text-align: justify;
  color: dimgray;
  font-size: 1.2rem;
  line-height: 1.5;
}
#biography-container h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}
#biography-container h3:hover > .edit-details-svg {
  display: inline-block !important;
}

.loading-img-container {
  position: absolute;
  background-color: rgba(16, 19, 21, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 20px;
}

#edit-user-info-text {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}

.edit-user-profile-btn {
  cursor: pointer;
  color: #5dade2;
  font-weight: bold;
  font-size: 1.1rem;
}

.verified-svg {
  font-size: 1.3rem;
}
#social-media-container {
  display: flex;
  margin-top: 6px !important;
}
#social-media-container svg {
  font-size: 1.2rem;
  color: var(--app-theme);
  cursor: pointer;
  margin-right: 17px;
}


.interests-container{
  margin-top: 2rem;
}
.interests-container h3{
   font-size: 1.6rem;
  color: var(--app-theme);
}
.interests-wrapper{
  display: grid;
 grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 15px;
}

.interest-item{
      padding: 10px 15px;
    background-color: white;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
}

.interest-item:hover{
    background-color:#F4D03F;
}
.user-settings-wrapper-root-container{
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}
@media (max-width: 480px) {
#profile-img {
  height: 87px !important;
  width: 89px !important;

}
#choose-icon {

    height: 29px;
    width: 31px;
    right: 7px;
    bottom: 55px;
}
#info-container {
    margin-left: 5px !important;
}
#info-container h3 {
    font-size: 1rem !important;
}
.interests-wrapper{
 grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;
}
.user-settings-wrapper-root-container{
  width: 100%;

}
}

#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 20px;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}

#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
}
#info-container P {

  margin-top: 8px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}

#biography-container {
 width: 90%;

}
#biography-container p {
  text-align: justify;
  color: dimgray;
  font-size: 1.1rem;
  line-height: 1.7;
}
#biography-container h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}


.verified-svg{
  font-size: 1.3rem;
}

.partnerships-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 30px;
  row-gap: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#social-media-container{
  display: flex;
  margin-top: 15px;
}
#social-media-container svg{
  font-size: 1.2rem;
  color: var(--app-theme);
    cursor: pointer;
  margin-right: 17px;
}

.enterprise-location-icon-container{
  display: flex;
}
.enterprise-location-icon-container svg{
  margin-right: 10px;
  font-size: 1.5rem;
}
.enterprise-location-icon-container p{
  font-size: 1.2rem;
}
.enterprise-location-text{
  color: grey;
  font-size: 1.1rem;
  margin-left: 15px;
}
#asset-detail-wrapper {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}

#asset-img {
  height: 120px;
  width: 140px;
  border-radius: 10px;
  padding: 1px;
  object-fit: cover;
  border: 2px solid var(--app-accent-pink);;
}
#asset-info-txts-container {
  margin-left: 25px;
}

#asset-detail-container,
#asset-doc-container {
  margin-top: 2.4rem;
}
#asset-doc-container {
  margin-bottom: 2rem;
}

#asset-detail-container p {
  text-align: justify;
  margin-top: 10px;
  line-height: 1.5;
}

#asset-info-txts-container h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--app-theme);
}
#asset-info-txts-container h2 span {
  font-size: 1.3rem;
  color: var(--app-theme);
  font-weight: normal;
}

.asset-id-number {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--app-theme);
}

.view-file-btn {
  padding: 10px 20px;
  color: black;
  background-color: white;
  border-radius: 30px;
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
}
.view-file-btn:hover {
  color: white;
  background-color: var(--app-accent-pink);
}


.img-and-title-container{
    display: flex;
}
#feed-detail-logo-container img{
border-radius: 10px;
height: 110px;
width: 110px;
margin-right: 15px;
object-position: center;
object-fit: cover;
border: 4px solid var(--app-accent-pink);
}
.feed-detail-root{
    width: 90%;
    margin: auto;
    margin-top: 1rem;
}
#feed-title-and-body-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 95%;
}

.feed-detail-body-container p{
line-height: 1.7;
text-align: justify;
font-size: 1.1rem;
margin-bottom: 20px;
}

.comments-list-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* padding: 15px 20px 0px 20px; */
    border-radius: 4px;
    border: 1px solid #ccc;
    /* max-height: 40vh;
    overflow-y: auto; */
    
}
#commenter-img-container img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:  2px solid rgb(138, 44, 44);
}

#commenter-info{
    margin-left:10px;
    margin-top: 5px;
}

#commenter-info small{
margin-left: 5px;
}

#comment-body{
margin-top: 10px;
}
#feed-detail-body-images img{
    width:100%;
    /* object-fit: cover; */
    height:65vh;
    /* height: 100%; */
    border-radius:10px;
}
#feed-detail-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:65vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#feed-detail-body-images{
 margin-top: 20px;
}

.comment-item-container{
padding-bottom: 10px;
border-bottom: 1px solid #ccc;
padding:10px;
/* margin-top: 10px; */
cursor: pointer;

}
.comment-item-container:hover{
background-color:whitesmoke;

}

#feed-detail-title-container{
    margin-bottom: 1rem;
    text-align: center;


}
#feed-detail-title-container h1{
color: var(--app-theme);
cursor: pointer;
}

.comments-header{
    margin-top: 10px;
    margin-left: 20px;
}

.feed-detail-body p{
    text-align: justify !important;
    font-size: 1.4rem !important;
}

@media (max-width: 480px) {
#feed-detail-logo-container img{
height: 63px;
width: 68px;
}
.feed-detail-root{
    margin-top: 0rem;
}
.feed-detail-body p{
    font-size: 1rem !important;
}
}
.country-and-currency-root{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.country-form-container{
    width: 60%;
}

.country-currency-notice{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
}
.country-currency-notice h3{
    color: maroon;
    font-size: 1.4em;
    font-weight: bold;
}
.country-currency-notice p{
    color: #666;
    font-size: 1.1em;
    margin-top: 5px;
}

@media (max-width: 480px) {
    .country-form-container{
        width: 90%;
    }
}

.overlay-root-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
